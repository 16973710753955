// File: src/context/ThemeProviderComponent.js

import React, { createContext, useState, useEffect, useMemo } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';

// ---------------------------
// Custom hook: useLocalStorage
// ---------------------------
function useLocalStorage(key, initialValue) {
  const isAvailable = (() => {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  })();

  const [storedValue, setStoredValue] = useState(() => {
    if (!isAvailable) return initialValue;
    const item = localStorage.getItem(key);
    if (item !== null) {
      try {
        return JSON.parse(item);
      } catch (e) {
        // If JSON.parse fails (e.g. for plain strings), return the raw value.
        return item;
      }
    }
    return initialValue;
  });

  useEffect(() => {
    if (isAvailable) {
      // When storing, use JSON.stringify so that objects and booleans are preserved.
      localStorage.setItem(key, JSON.stringify(storedValue));
    }
  }, [key, storedValue, isAvailable]);

  return [storedValue, setStoredValue];
}


// Create our ThemeContext to provide toggling functions
export const ThemeContext = createContext();

// Simple contrast text function (could be replaced by MUI's getContrastText)
function getContrastText(bgColor, lightText = '#ffffff', darkText = '#000000') {
  const color = bgColor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16) / 255;
  const g = parseInt(color.substr(2, 2), 16) / 255;
  const b = parseInt(color.substr(4, 2), 16) / 255;
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 0.5 ? darkText : lightText;
}

// ---------------------------
// Theme configuration constants (Improvement 2)
// ---------------------------
const themeConfig = {
  colors: {
    primary: '#0052cc',
    secondary: '#009688',
    success: '#28a745',
    warning: '#ff9800',
    error:   '#f44336',
    info:    '#2196f3',
    grey: {
      50:  '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  background: {
    light: { default: '#fafafa', paper: '#ffffff' },
    dark: { default: '#121212', paper: '#1f1f1f' },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    baseFontSize: 14,
  },
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
  },
};

// ---------------------------
// ThemeProviderComponent
// ---------------------------
const ThemeProviderComponent = ({ children }) => {
  // Use custom hook for localStorage operations
  const [mode, setMode] = useLocalStorage(
    'appTheme',
    typeof window !== 'undefined' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  );
  const [largeTextEnabled, setLargeTextEnabled] = useLocalStorage('largeTextEnabled', false);
  const [locale, setLocale] = useState('en-US');

  // Listen for changes to system color scheme if user hasn't explicitly set a theme.
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleSystemChange = (e) => {
      if (!localStorage.getItem('appTheme')) {
        setMode(e.matches ? 'dark' : 'light');
      }
    };
    mediaQuery.addEventListener('change', handleSystemChange);
    return () => mediaQuery.removeEventListener('change', handleSystemChange);
  }, [setMode]);

  const toggleTheme = () => setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  const toggleLargeText = () => setLargeTextEnabled((prev) => !prev);

  const baseFontSize = largeTextEnabled ? 18 : themeConfig.typography.baseFontSize;

  // ---------------------------
  // Build the theme (Improvement 3)
  // ---------------------------
  const theme = useMemo(() => {
    // Base theme object
    let newTheme = createTheme({
      palette: {
        mode,
        primary: {
          main: themeConfig.colors.primary,
          contrastText: getContrastText(themeConfig.colors.primary),
        },
        secondary: {
          main: themeConfig.colors.secondary,
          contrastText: getContrastText(themeConfig.colors.secondary),
        },
        success: {
          main: themeConfig.colors.success,
          contrastText: getContrastText(themeConfig.colors.success),
        },
        warning: {
          main: themeConfig.colors.warning,
          contrastText: getContrastText(themeConfig.colors.warning),
        },
        error: {
          main: themeConfig.colors.error,
          contrastText: getContrastText(themeConfig.colors.error),
        },
        info: {
          main: themeConfig.colors.info,
          contrastText: getContrastText(themeConfig.colors.info),
        },
        grey: themeConfig.colors.grey,
        background: {
          default: mode === 'dark'
            ? themeConfig.background.dark.default
            : themeConfig.background.light.default,
          paper: mode === 'dark'
            ? themeConfig.background.dark.paper
            : themeConfig.background.light.paper,
        },
        text: {
          primary: mode === 'dark' ? '#ffffff' : '#333333',
          secondary: mode === 'dark' ? '#cccccc' : '#555555',
        },
      },
      shape: themeConfig.shape,
      breakpoints: themeConfig.breakpoints,
      typography: {
        fontSize: baseFontSize,
        fontFamily: themeConfig.typography.fontFamily,
        h1: { fontWeight: 700 },
        h2: { fontWeight: 700 },
        h3: { fontWeight: 600 },
        h4: { fontWeight: 600 },
        button: {
          textTransform: 'none',
          fontWeight: 500,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              scrollBehavior: 'smooth',
            },
            body: {
              transition: 'background-color 0.3s ease, color 0.3s ease',
            },
            a: {
              textDecoration: 'none',
              color: themeConfig.colors.primary,
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              transition: 'background-color 0.3s, color 0.3s',
              '@media (max-width:600px)': {
                padding: '8px 16px',
                fontSize: '0.875rem',
              },
            },
            containedPrimary: {
              '&:hover': {
                backgroundColor: '#003da5',
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 12,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              marginTop: 8,
              marginBottom: 8,
              '@media (max-width:600px)': {
                marginTop: 4,
                marginBottom: 4,
              },
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              '@media (max-width:600px)': {
                width: '75vw',
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              '@media (max-width:600px)': {
                height: 56,
              },
              transition: 'height 0.3s ease',
            },
          },
        },
      },
      custom: {
        locale,
        dateFormat: 'MM/dd/yyyy',
        timeFormat: 'HH:mm',
      },
    });

    // Make typography responsive.
    newTheme = responsiveFontSizes(newTheme, { factor: 2.0 });

    // Merge in responsive overrides for smaller screens.
    newTheme = deepmerge(newTheme, {
      typography: {
        h1: { [newTheme.breakpoints.down('sm')]: { fontSize: '2rem' } },
        h2: { [newTheme.breakpoints.down('sm')]: { fontSize: '1.75rem' } },
        h3: { [newTheme.breakpoints.down('sm')]: { fontSize: '1.5rem' } },
        body1: { [newTheme.breakpoints.down('sm')]: { fontSize: `${baseFontSize - 2}px` } },
      },
    });

    return newTheme;
  }, [
    mode,
    largeTextEnabled,
    baseFontSize,
    locale,
  ]);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        toggleTheme,
        largeTextEnabled,
        toggleLargeText,
        locale,
        setLocale,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderComponent;
