// File: src/components/BigDashboardMegaMenu.js

import React, { useState, useMemo } from 'react';
import {
  Menu,
  MenuList,
  MenuItem,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Fade,
  Dialog,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const BigDashboardMegaMenu = ({ anchorEl, open, onClose, dashboards, objectTypes }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [fullScreenOpen, setFullScreenOpen] = useState(false);

  // Filter dashboards based on the search term.
  const filteredDashboards = useMemo(() => {
    if (!searchTerm) return dashboards;
    return dashboards.filter((d) =>
      (d.label || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [dashboards, searchTerm]);

  // Filter object types based on the search term.
  const filteredObjectTypes = useMemo(() => {
    if (!searchTerm) return objectTypes;
    return objectTypes.filter((o) =>
      (o.title || o.name || o.id).toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [objectTypes, searchTerm]);

  // Handle dashboard selection.
  const handleSelectDashboard = (dashboard) => {
    navigate(`/dashboard?view=${encodeURIComponent(dashboard.value)}`);
    onClose();
  };

  // Handle object type selection.
  const handleSelectObjectType = (objectType) => {
    navigate(`/list/${objectType.id}`);
    onClose();
    setFullScreenOpen(false);
  };

  // Full screen handlers.
  const openFullScreen = () => {
    setFullScreenOpen(true);
  };

  const closeFullScreen = () => {
    setFullScreenOpen(false);
  };

  return (
    <>
      {/* Standard Menu View */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            width: { xs: '95vw', md: '600px' },
            maxHeight: '75vh',
            overflowY: 'auto',
            p: 2,
          },
        }}
      >
        {/* Search Bar */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            placeholder="Search dashboards or items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* Dashboards Section */}
        <Typography variant="h6" sx={{ mb: 1 }}>
          Dashboards
        </Typography>
        <MenuList>
          {filteredDashboards.length === 0 ? (
            <Typography variant="body2" sx={{ pl: 2 }}>
              No dashboards found.
            </Typography>
          ) : (
            filteredDashboards.map((dash) => (
              <MenuItem key={dash.value} onClick={() => handleSelectDashboard(dash)}>
                <Typography>{dash.label}</Typography>
              </MenuItem>
            ))
          )}
        </MenuList>

        <Divider sx={{ my: 2 }} />

        {/* All Items Section */}
        <Typography variant="h6" sx={{ mb: 1 }}>
          All Items
        </Typography>
        <MenuList>
          {filteredObjectTypes.length === 0 ? (
            <Typography variant="body2" sx={{ pl: 2 }}>
              No items found.
            </Typography>
          ) : (
            filteredObjectTypes.map((item) => (
              <MenuItem key={item.id} onClick={() => handleSelectObjectType(item)}>
                <Typography>{item.title || item.name || item.id}</Typography>
              </MenuItem>
            ))
          )}
        </MenuList>

        {/* NEW: Reports Section */}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          Reports
        </Typography>
        <MenuList>
          <MenuItem onClick={() => { navigate('/reports'); onClose(); }}>
            <Typography>All Reports</Typography>
          </MenuItem>
          <MenuItem onClick={() => { navigate('/reports/create'); onClose(); }}>
            <Typography>Create Report</Typography>
          </MenuItem>
        </MenuList>

        {/* Footer Buttons */}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              navigate('/dashboard');
              onClose();
            }}
          >
            View Full Dashboard
          </Button>
          <Button variant="text" onClick={openFullScreen} sx={{ ml: 1 }}>
            Full Screen
          </Button>
        </Box>
      </Menu>

      {/* Full Screen Dialog for Object Types */}
      <Dialog
        open={fullScreenOpen}
        onClose={closeFullScreen}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeFullScreen} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
              Object Types
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 3 }}>
          {/* Persistent search bar */}
          <TextField
            fullWidth
            placeholder="Search object types..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="medium"
            sx={{ mb: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />

          {/* Grid layout for object type cards */}
          <Grid container spacing={3}>
            {filteredObjectTypes.length === 0 ? (
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  No object types found.
                </Typography>
              </Grid>
            ) : (
              filteredObjectTypes.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Paper
                    sx={{
                      p: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      transition: 'box-shadow 0.3s',
                      '&:hover': { boxShadow: 6 },
                    }}
                    onClick={() => handleSelectObjectType(item)}
                  >
                    <Box>
                      <Typography variant="h6">
                        {item.title || item.name || item.id}
                      </Typography>
                      {item.description && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {item.description}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ mt: 2, textAlign: 'right' }}>
                      <Button variant="contained" size="small" onClick={() => handleSelectObjectType(item)}>
                        View
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))
            )}
          </Grid>
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Button variant="contained" onClick={closeFullScreen}>
              Close Full Screen
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BigDashboardMegaMenu;
