// File: src/components/Navbar/MobileLoggedOutView.jsx

import React, { useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem, Divider, Stack } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

export default function MobileLoggedOutView({ iconButtonStyles }) {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title="Open menu" arrow>
        <IconButton
          onClick={handleMenuOpen}
          sx={iconButtonStyles}
          aria-label="Open mobile menu"
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            minWidth: 200,
            backgroundColor: '#212121',
            color: '#fff',
            '& .MuiMenuItem-root': {
              color: '#fff',
              '&:hover': { backgroundColor: '#424242' },
            },
            '& .MuiDivider-root': {
              backgroundColor: 'rgba(255,255,255,0.2)',
            },
          },
        }}
      >
        {/* The "Contact Sales" link now directs to the default page */}
        <MenuItem
          component={RouterLink}
          to="/"
          onClick={handleMenuClose}
        >
          Contact Sales
        </MenuItem>
        <Divider />
        <MenuItem
          component={RouterLink}
          to="/signin"
          onClick={handleMenuClose}
        >
          Sign In
        </MenuItem>
      </Menu>
    </Stack>
  );
}
