import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Tooltip,
  Divider,
  Collapse,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddBox from '@mui/icons-material/AddBox';
import BarChartIcon from '@mui/icons-material/BarChart';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Notifications from './Notifications';
import NavbarSearchBar from './NavbarSearchBar';
import BigProfileMegaMenu from '../layout/Components/Navbar/BigProfileMegaMenu';
import BigSupportMegaMenu from '../layout/Components/Navbar/BigSupportMegaMenu';
import BigGuidanceCentreMegaMenu from '../layout/Components/Navbar/BigGuidanceCentreMegaMenu';
import GlobalActionsMenu from './GlobalActionsMenu';
import BigDashboardMegaMenu from '../../pages/Users/Dashboard/components/BigDashboardMegaMenu';
import QuickLinksBar from '../../pages/Users/Dashboard/components/QuickLinksBar';
import { useDashboardData } from '../../context/DashboardDataContext';
import { ThemeContext } from '../../context/ThemeProviderComponent';

// Import your AuthContext to pull userProfile & currentUser
import { AuthContext } from '../../context/AuthContext';

// "Home" is always present.
const defaultDashboards = [{ label: 'Home', value: 'Home' }];

function AppHeader() {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // 1) Destructure userProfile and currentUser from AuthContext
  const { userProfile, currentUser } = useContext(AuthContext);

  // 2) Derive userName and userAvatar from userProfile or currentUser
  const userName =
    userProfile?.displayName ||
    currentUser?.displayName ||
    currentUser?.email ||
    '';
  const userAvatar =
    userProfile?.profilePhoto ||
    currentUser?.photoURL ||
    '';

  // If userAvatar is empty, we'll display the first letter of userName
  const fallbackLetter = useMemo(() => userName.charAt(0) || 'U', [userName]);

  const { mode, toggleTheme } = useContext(ThemeContext);
  const { solutions, customDashboards, objectTypes, dashboardView, setDashboardView } = useDashboardData();

  // Update global dashboard view from the URL if a ?view= parameter exists
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const view = searchParams.get('view');
    if (view) {
      setDashboardView(view);
    }
  }, [location.search, setDashboardView]);

  // Merge "Home" with solutions and custom dashboards
  const megaDashboards = useMemo(() => {
    return [...defaultDashboards, ...solutions, ...customDashboards];
  }, [solutions, customDashboards]);

  // Identify which dashboard is currently selected
  const selectedDashboard =
    dashboardView !== 'Home'
      ? megaDashboards.find((d) => d.value === dashboardView)
      : null;
  const selectedDashboardName = selectedDashboard ? selectedDashboard.label : 'Home';

  // Active Dashboard Highlighting style
  const dashboardTitleStyle =
    selectedDashboardName !== 'Home'
      ? { fontWeight: 'bold', borderBottom: `2px solid ${theme.palette.primary.main}` }
      : {};

  // State for the BigDashboardMegaMenu anchor
  const [megaMenuAnchor, setMegaMenuAnchor] = useState(null);
  const handleMegaMenuOpen = (e) => setMegaMenuAnchor(e.currentTarget);
  const handleMegaMenuClose = () => setMegaMenuAnchor(null);

  // States for right-hand menus
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [supportAnchorEl, setSupportAnchorEl] = useState(null);
  const [globalActionsAnchorEl, setGlobalActionsAnchorEl] = useState(null);
  const [guidanceAnchorEl, setGuidanceAnchorEl] = useState(null);

  // NEW: State for the mobile "More" menu anchor
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const handleMoreMenuOpen = (e) => setMoreAnchorEl(e.currentTarget);
  const handleMoreMenuClose = () => setMoreAnchorEl(null);

  // UX improvement: ESC key closes the search bar
  const [searchOpen, setSearchOpen] = useState(false);

  // Handlers for each menu
  const handleProfileMenuOpen = (e) => setProfileAnchorEl(e.currentTarget);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);
  const handleSupportMenuOpen = (e) => setSupportAnchorEl(e.currentTarget);
  const handleSupportMenuClose = () => setSupportAnchorEl(null);
  const handleGlobalActionsOpen = (e) => setGlobalActionsAnchorEl(e.currentTarget);
  const handleGlobalActionsClose = () => setGlobalActionsAnchorEl(null);
  const handleGuidanceMenuOpen = (e) => setGuidanceAnchorEl(e.currentTarget);
  const handleGuidanceMenuClose = () => setGuidanceAnchorEl(null);

  // Auto-focus the search bar input as soon as it opens
  const [searchFocus, setSearchFocus] = useState(false);
  const handleOpenSearch = () => {
    setSearchOpen(true);
    setSearchFocus(true);
  };

  // Adjust icon styles for mobile (larger tap targets)
  const iconButtonStyle = { p: isSmallScreen ? 1 : 0.5, color: theme.palette.text.primary };
  const iconStyle = { fontSize: isSmallScreen ? 28 : 24, color: theme.palette.text.primary };

  return (
    <>
      {/* Spacer for the fixed header */}
      <Box sx={{ height: '64px' }} />

      <Box
        component="header"
        role="banner"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          zIndex: theme.zIndex.appBar,
        }}
      >
        {/* TOP ROW */}
        <Box
          component="div"
          role="navigation"
          sx={{
            height: 64,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* LEFT: Dashboard icon, title, and (desktop) QuickLinks */}
          <Stack direction="row" spacing={isSmallScreen ? 1 : 2} alignItems="center">
            <Tooltip title="Open Dashboard Menu" arrow>
              <IconButton onClick={handleMegaMenuOpen} sx={iconButtonStyle} aria-label="Open dashboard menu">
                <DashboardIcon sx={iconStyle} />
              </IconButton>
            </Tooltip>

            <Typography variant="h6" sx={{ lineHeight: 1.2, ...dashboardTitleStyle }}>
              {selectedDashboardName}
            </Typography>

            {/* Desktop: Show QuickLinks */}
            {!isSmallScreen && (
              <QuickLinksBar dashboardModule={dashboardView} objectTypes={objectTypes} />
            )}
          </Stack>

          {/* RIGHT: Mobile vs. Desktop rendering */}
          {isSmallScreen ? (
            // Mobile layout: show Search, Notifications, Profile, and a More menu
            <Stack direction="row" alignItems="center" spacing={1}>
              {searchOpen ? (
                <Collapse in={searchOpen} orientation="horizontal" sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ flexGrow: 1 }}>
                    <NavbarSearchBar userId="mockUserId" autoFocus={searchFocus} fullWidth />
                    <IconButton onClick={() => setSearchOpen(false)} sx={iconButtonStyle} aria-label="Close search bar">
                      <CloseIcon sx={iconStyle} />
                    </IconButton>
                  </Stack>
                </Collapse>
              ) : (
                <IconButton onClick={handleOpenSearch} sx={iconButtonStyle} aria-label="Open search bar">
                  <SearchIcon sx={iconStyle} />
                </IconButton>
              )}

              <Notifications userId="mockUserId" />

              <Tooltip title={userName ? `Profile: ${userName}` : 'Profile menu'} arrow>
                <IconButton onClick={handleProfileMenuOpen} sx={iconButtonStyle} aria-label="Open profile menu">
                  <Avatar src={userAvatar} alt={userName}>
                    {userAvatar ? '' : fallbackLetter}
                  </Avatar>
                </IconButton>
              </Tooltip>

              <IconButton onClick={handleMoreMenuOpen} sx={iconButtonStyle} aria-label="Open more menu">
                <MoreVertIcon sx={iconStyle} />
              </IconButton>

              <Menu
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleMoreMenuClose}
                PaperProps={{ style: { width: 250 } }}
              >
                <MenuItem
                  onClick={(e) => {
                    handleGlobalActionsOpen(e);
                    handleMoreMenuClose();
                  }}
                >
                  <AddBox sx={{ mr: 1 }} />
                  <Typography>Global Actions</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/reports" onClick={handleMoreMenuClose}>
                  <BarChartIcon sx={{ mr: 1 }} />
                  <Typography>Reports</Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleGuidanceMenuOpen(e);
                    handleMoreMenuClose();
                  }}
                >
                  <LightbulbCircleIcon sx={{ mr: 1 }} />
                  <Typography>Guidance Centre</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/orthinas-connect" onClick={handleMoreMenuClose}>
                  <ChatBubbleOutlineIcon sx={{ mr: 1 }} />
                  <Typography>Orthinas Connect</Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleSupportMenuOpen(e);
                    handleMoreMenuClose();
                  }}
                >
                  <HelpOutlineIcon sx={{ mr: 1 }} />
                  <Typography>Help & Support</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleTheme();
                    handleMoreMenuClose();
                  }}
                >
                  <NightsStayIcon sx={{ mr: 1 }} />
                  <Typography>Toggle Theme</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/account" onClick={handleMoreMenuClose}>
                  <RocketLaunchIcon sx={{ mr: 1 }} />
                  <Typography>Upgrade</Typography>
                </MenuItem>
              </Menu>
            </Stack>
          ) : (
            // Desktop layout: show all icons
            <Stack direction="row" alignItems="center" spacing={1}>
              {searchOpen ? (
                <Collapse in={searchOpen} orientation="horizontal">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <NavbarSearchBar userId="mockUserId" autoFocus={searchFocus} />
                    <IconButton onClick={() => setSearchOpen(false)} sx={iconButtonStyle} aria-label="Close search bar">
                      <CloseIcon sx={iconStyle} />
                    </IconButton>
                  </Stack>
                </Collapse>
              ) : (
                <IconButton onClick={handleOpenSearch} sx={iconButtonStyle} aria-label="Open search bar">
                  <SearchIcon sx={iconStyle} />
                </IconButton>
              )}

              <Tooltip title="Global Actions" arrow>
                <IconButton onClick={handleGlobalActionsOpen} sx={iconButtonStyle} aria-label="Open global actions">
                  <AddBox sx={iconStyle} />
                </IconButton>
              </Tooltip>
              <GlobalActionsMenu
                anchorEl={globalActionsAnchorEl}
                open={Boolean(globalActionsAnchorEl)}
                onClose={handleGlobalActionsClose}
              />

              <Tooltip title="Analysis & Reports" arrow>
                <IconButton component={Link} to="/reports" sx={iconButtonStyle} aria-label="View reports">
                  <BarChartIcon sx={iconStyle} />
                </IconButton>
              </Tooltip>

              <Notifications userId="mockUserId" />

              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

              <Tooltip title="Guidance Centre" arrow>
                <IconButton onClick={handleGuidanceMenuOpen} sx={iconButtonStyle} aria-label="Open guidance centre">
                  <LightbulbCircleIcon sx={iconStyle} />
                </IconButton>
              </Tooltip>
              <BigGuidanceCentreMegaMenu
                anchorEl={guidanceAnchorEl}
                open={Boolean(guidanceAnchorEl)}
                onClose={handleGuidanceMenuClose}
                selectedModules={[]}
              />

              <Tooltip title="Orthinas Connect" arrow>
                <IconButton component={Link} to="/orthinas-connect" sx={iconButtonStyle} aria-label="Open Orthinas Connect">
                  <ChatBubbleOutlineIcon sx={iconStyle} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Open Help & Support" arrow>
                <IconButton onClick={handleSupportMenuOpen} sx={iconButtonStyle} aria-label="Open help & support">
                  <HelpOutlineIcon sx={iconStyle} />
                </IconButton>
              </Tooltip>
              <BigSupportMegaMenu
                anchorEl={supportAnchorEl}
                open={Boolean(supportAnchorEl)}
                onClose={handleSupportMenuClose}
              />

              <Tooltip title={`Switch to ${mode === 'dark' ? 'light' : 'dark'} theme`} arrow>
                <IconButton onClick={toggleTheme} sx={iconButtonStyle} aria-label="Toggle theme">
                  <NightsStayIcon sx={iconStyle} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Upgrade your account" arrow>
                <Button
                  component={Link}
                  to="/account"
                  variant="contained"
                  size="small"
                  startIcon={<RocketLaunchIcon sx={iconStyle} />}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '20px',
                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    color: 'white',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
                    px: 2,
                    py: 0.5,
                    fontWeight: 'bold',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
                    },
                  }}
                  aria-label="Upgrade your account"
                >
                  Upgrade
                </Button>
              </Tooltip>

              <Tooltip title={userName ? `Profile: ${userName}` : 'Profile menu'} arrow>
                <IconButton onClick={handleProfileMenuOpen} sx={iconButtonStyle} aria-label="Open profile menu">
                  <Avatar src={userAvatar} alt={userName}>
                    {/* If no avatar URL, show the fallback letter */}
                    {userAvatar ? '' : fallbackLetter}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <BigProfileMegaMenu
                anchorEl={profileAnchorEl}
                open={Boolean(profileAnchorEl)}
                onClose={handleProfileMenuClose}
              />
            </Stack>
          )}
        </Box>

        <BigDashboardMegaMenu
          anchorEl={megaMenuAnchor}
          open={Boolean(megaMenuAnchor)}
          onClose={handleMegaMenuClose}
          dashboards={megaDashboards}
          objectTypes={objectTypes}
        />
      </Box>
    </>
  );
}

export default AppHeader;
