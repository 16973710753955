import React, { Suspense } from 'react';
import { Routes, Route, useLocation, matchRoutes } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

// Theme, Auth & Subscription Context Providers
import ThemeProviderComponent from './context/ThemeProviderComponent';
import { AuthProvider } from './context/AuthContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Our new data context
import { DashboardDataProvider } from './context/DashboardDataContext';

// Routing & Guards
import { publicRoutes } from './routes/publicRoutes';
import { protectedRoutes } from './routes/protectedRoutes';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingSpinner from './components/LoadingSpinner';
import LoggingOutPage from './components/LoggingOutPage';

import { getSectionConfig } from './utils/sectionUtils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// Layout Components (lazy loaded where appropriate)
import AppHeader from './components/Navbar/AppHeader';
import Navbar from './components/layout/Components/Navbar';
const AppFooter = React.lazy(() => import('./components/Navbar/AppFooter'));
const Footer = React.lazy(() => import('./components/layout/Components/Footer'));
const CookiePopup = React.lazy(() => import('./components/CookiePopup'));


// ErrorBoundary component to catch errors in lazy-loaded routes
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <Box sx={{ textAlign: 'center', mt: 6 }}>Something went wrong.</Box>;
    }
    return this.props.children;
  }
}

function LoadingWrapper() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <LoadingSpinner aria-label="Loading" />
    </Box>
  );
}

function AppRoutes() {
  return (
    <Suspense fallback={<LoadingWrapper />}>
      <ErrorBoundary>
        <Routes>
          {/* Logging Out route accessible to all users */}
          <Route path="/logging-out" element={<LoggingOutPage />} />

          {/* Public routes */}
          {publicRoutes.map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}

          {/* Protected routes */}
          {protectedRoutes.map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
}

export default function App() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Standard layout logic
  const navHeight = theme.mixins.toolbar.minHeight;
  const section = getSectionConfig(location.pathname);
  const isSubNavShowing = !!section;
  let contentOffset = navHeight;
  if (isSubNavShowing) {
    contentOffset += 64;
  }

  // Hide layout logic for specific routes
  const hideLayout =
    location.pathname.startsWith('/public/') ||
    (location.pathname.startsWith('/engagehub/') &&
      !location.pathname.startsWith('/engagehub/create') &&
      !location.pathname.startsWith('/engagehub/edit'));

  // Check if route is protected
  const isProtectedRoute = Boolean(matchRoutes(protectedRoutes, location));

  return (
    <ThemeProviderComponent>
      <AuthProvider>
        <SubscriptionProvider>
          <DashboardDataProvider>
            <HelmetProvider>
              {/* Default global meta tags */}
              <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="RCF Orthinas - Your source for CRM insights, news, and guides." />
                <link rel="canonical" href="https://orthinascrm.com" />
              </Helmet>
              <Box display="flex" flexDirection="column" minHeight="100vh">
                {/* Use AppHeader if protected, else old Navbar */}
                {!hideLayout && (
                  isProtectedRoute ? (
                    <AppHeader />
                  ) : (
                    <Navbar logo="./RCFOrthinasLight.png" navbarOffset={0} />
                  )
                )}

                {/* Main content */}
                <Box
                  component="main"
                  flex={1}
                  sx={{ mt: !hideLayout ? `${contentOffset}px` : 0 }}
                >
                  <DndProvider backend={HTML5Backend}>
                    <AppRoutes />
                  </DndProvider>
                </Box>

                {/* Footers */}
                <Suspense fallback={<LoadingWrapper />}>
                  {isProtectedRoute ? <AppFooter /> : <Footer />}
                </Suspense>
              </Box>
              {/* Lazy loaded CookiePopup */}
              <Suspense fallback={null}>
                <CookiePopup />
              </Suspense>
            </HelmetProvider>
          </DashboardDataProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </ThemeProviderComponent>
  );
}
