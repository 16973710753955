import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Box,
  useMediaQuery,
  styled,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import BigSupportMegaMenu from '/Users/willradley/orthinas-crm/src/components/layout/Components/Navbar/BigSupportMegaMenu.jsx';

// Icons
import { Menu as MenuIcon } from '@mui/icons-material';

// Our old "logged-out" views
import DesktopLoggedOutNavItems from '../Desktop/DesktopLoggedOutNavItems.jsx';
import DesktopLoggedOutRightItems from '../Desktop/DesktopLoggedOutRightItems.jsx';
import MobileLoggedOutView from '../Mobile/MobileLoggedOutView.jsx';

// Commerce sub-navigation, if you still want to show it
import CommerceNavBar from '../../MultiSectionSubNav';

// Dark gradient background for AppBar
const GRADIENT_BG = 'linear-gradient(90deg, #212121, #424242)';

// Style objects for buttons/icons/etc.
export const buttonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: '20px',
  px: 2,
  color: '#ffffff',
  boxShadow: 'none',
  backgroundColor: 'rgba(255,255,255,0.2)',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
};

export const iconButtonStyles = {
  color: '#fff',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.15)',
  },
};

/**
 * StyledAppBar uses a style callback that reads `navbarOffset` from props.
 */
const StyledAppBar = styled(AppBar)(({ theme, navbarOffset }) => ({
  position: 'fixed',
  top: navbarOffset,
  zIndex: theme.zIndex.drawer + 1,
  background: GRADIENT_BG,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 0,
  boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
}));

/**
 * Navbar
 * @param {string} logo - Path to your logo image
 * @param {number} navbarOffset - Offset from the top (e.g., 64 if banner is open, 0 if not)
 */
const Navbar = ({ logo, navbarOffset = 64 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  // If you still want to show a sub-nav for commerce pages
  const isCommerceSection = location.pathname.startsWith('/commerce/');

  // State for the help (support) menu and mobile menu (for logged out)
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  // Handlers for help & mobile menus
  const handleHelpMenuOpen = (e) => setHelpAnchorEl(e.currentTarget);
  const handleHelpMenuClose = () => setHelpAnchorEl(null);

  const handleMobileMenuOpen = (e) => setMobileAnchorEl(e.currentTarget);
  const handleMobileMenuClose = () => setMobileAnchorEl(null);

  // Logged-out views
  const desktopLoggedOutNavItems = <DesktopLoggedOutNavItems buttonStyles={buttonStyles} />;
  const desktopLoggedOutRightItems = (
    <DesktopLoggedOutRightItems
      buttonStyles={buttonStyles}
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
    />
  );
  const mobileLoggedOutView = (
    <MobileLoggedOutView
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
      mobileAnchorEl={mobileAnchorEl}
      handleMobileMenuOpen={handleMobileMenuOpen}
    />
  );

  return (
    <>
      <StyledAppBar navbarOffset={navbarOffset} elevation={4}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {/* LEFT: Logo + (Desktop Nav if not mobile, for logged-out) */}
          <Box display="flex" alignItems="center" gap={2}>
            <Link
              to="/"
              style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              {logo && (
                <Box
                  component="img"
                  src={logo}
                  alt="Logo"
                  sx={{ height: 22, width: 'auto', mr: 1 }}
                />
              )}
            </Link>

            {/* Desktop logged-out nav items */}
            {!isMobile && desktopLoggedOutNavItems}
          </Box>

          {/* RIGHT: Logged-out */}
          <Box display="flex" alignItems="center" gap={2}>
            {isMobile ? mobileLoggedOutView : desktopLoggedOutRightItems}
          </Box>
        </Toolbar>

        {/* Big Support Menu (Help & Support) */}
        <BigSupportMegaMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          onClose={handleHelpMenuClose}
        />
      </StyledAppBar>

      {/* If user is in /commerce path, render a commerce sub-navigation */}
      {isCommerceSection && <CommerceNavBar />}
    </>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string,
  navbarOffset: PropTypes.number,
};

// Set default logo if desired
Navbar.defaultProps = {
  logo:
    'https://firebasestorage.googleapis.com/v0/b/finli-efed8.firebasestorage.app/o/RCFOrthinasLight.png?alt=media&token=a7e2d893-dd56-47c4-9328-9a64d4be8f21',
};

export default React.memo(Navbar);
