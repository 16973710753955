// DashboardDataContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

export const DashboardDataContext = createContext();

export function DashboardDataProvider({ children }) {
  const [user] = useAuthState(auth);
  const [solutions, setSolutions] = useState([]);
  const [customDashboards, setCustomDashboards] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  
  // New state for the selected dashboard view.
  const [dashboardView, setDashboardView] = useState('Home');

  // Fetch Solutions
  useEffect(() => {
    const q = query(collection(db, 'solutions'), where('type', '==', 'product'));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const solData = snapshot.docs.map((doc) => ({
          label: doc.data().title,
          value: doc.data().title,
          ...doc.data(),
        }));
        setSolutions(solData);
      },
      (error) => {
        console.error('Error fetching solutions:', error);
      }
    );
    return () => unsubscribe();
  }, []);

  // Fetch Custom Dashboards
  useEffect(() => {
    if (!user) {
      setCustomDashboards([]);
      return;
    }
    const dashboardsQ = query(collection(db, 'dashboards'), where('userId', '==', user.uid));
    const unsubscribeDashboards = onSnapshot(
      dashboardsQ,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          label: doc.data().title,
          value: doc.data().title,
          ...doc.data(),
        }));
        setCustomDashboards(data);
      },
      (error) => {
        console.error('Error fetching custom dashboards:', error);
      }
    );
    return () => unsubscribeDashboards();
  }, [user]);

  // Fetch Object Types
  useEffect(() => {
    if (!user) {
      setObjectTypes([]);
      return;
    }
    const systemQ = query(collection(db, 'objectTypes'), where('system', '==', true));
    const unsubscribeSystem = onSnapshot(
      systemQ,
      (snapshot) => {
        const sysData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setObjectTypes((prev) => {
          const existingIds = new Set(prev.map((p) => p.id));
          const merged = [...prev, ...sysData.filter((d) => !existingIds.has(d.id))];
          return merged;
        });
      },
      (error) => {
        console.error('Error fetching system object types:', error);
      }
    );

    const userQ = query(collection(db, 'objectTypes'), where('userId', '==', user.uid));
    const unsubscribeUser = onSnapshot(
      userQ,
      (snapshot) => {
        const userData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setObjectTypes((prev) => {
          const existingIds = new Set(prev.map((p) => p.id));
          const merged = [...prev, ...userData.filter((u) => !existingIds.has(u.id))];
          return merged;
        });
      },
      (error) => {
        console.error('Error fetching user object types:', error);
      }
    );

    return () => {
      unsubscribeSystem();
      unsubscribeUser();
    };
  }, [user]);

  // Expose everything including the new dashboardView state.
  const value = {
    solutions,
    customDashboards,
    objectTypes,
    dashboardView,
    setDashboardView,
  };

  return (
    <DashboardDataContext.Provider value={value}>
      {children}
    </DashboardDataContext.Provider>
  );
}

export function useDashboardData() {
  return useContext(DashboardDataContext);
}
