// File: src/components/Navbar/DesktopLoggedOutRightItems.jsx

import React from 'react';
import { Tooltip, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

// A simple outlined button that acts as a link.
function OutlinedButtonLink({ tooltip, ariaLabel, to, label, buttonStyles }) {
  return (
    <Tooltip title={tooltip} arrow>
      <Button
        component={Link}
        to={to}
        variant="outlined"
        sx={{
          ...buttonStyles,
          textTransform: 'none',
          fontWeight: 'bold',
          backgroundColor: 'transparent',
          borderColor: 'rgba(255,255,255,0.7)',
          transition: 'background-color 0.3s, border-color 0.3s, transform 0.3s',
          '&:hover': {
            borderColor: '#fff',
            backgroundColor: 'rgba(255,255,255,0.2)',
            transform: 'scale(1.05)',
          },
        }}
        aria-label={ariaLabel}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

export default function DesktopLoggedOutRightItems({ buttonStyles }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
  
      {/* Sign In Button */}
      <OutlinedButtonLink
        tooltip="Sign in to your account"
        ariaLabel="Sign in"
        to="/"
        label="Sign In"
        buttonStyles={buttonStyles}
      />
    </Stack>
  );
}
