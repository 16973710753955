import React, { lazy } from 'react';
import ProtectedRoute from '../components/ProtectedRoute';

// ----- ADMIN / SPECIAL PAGES -----
const CreateBlogPost = lazy(() => import('../pages/Resources/Blog/CreateBlogPost.jsx'));
const AdminCreateNotification = lazy(() => import('../pages/Users/AdminOnly/AdminCreateNotification.jsx'));

// ----- RCF UNITY DASHBOARD (Admin Only) -----
const RcfUnityDashboard = lazy(() => import('../pages/Users/AdminOnly/RcfUnityDashboard.js/RcfUnityDashboard.js'));

// ----- SETTINGS / ACCOUNT -----
const Settings = lazy(() => import('../pages/Users/Settings/Settings.jsx'));
const YourAccount = lazy(() => import('../pages/Users/Settings/YourAccount.jsx'));

// ----- DASHBOARD / DOCS -----
const Dashboard = lazy(() => import('../pages/Users/Dashboard/Dashboard.js'));
const ArticlesDocumentation = lazy(() => import('../pages/Resources/support/ArticlesDocumentation.js'));

// ----- OBJECT TYPES + OBJECT ITEMS -----
const ObjectTypeForm = lazy(() => import('../pages/Users/Objects/ObjectTypeForm.jsx'));
const ObjectItemForm = lazy(() => import('../pages/Users/Objects/ObjectItemForm.jsx'));
const ListObjectsPage = lazy(() => import('../pages/Users/Objects/ListObjectsPage.jsx'));

// ----- PUBLIC FORM EDITOR (Protected) -----
const FormEditorPage = lazy(() => import('../pages/Users/Objects/FormEditorPage.jsx'));

// ----- REPORTS -----
const ReportForm = lazy(() => import('../pages/Users/Reports/ReportForm.jsx'));
const ListReportsPage = lazy(() => import('../pages/Users/Reports/ListReportsPage.jsx'));

// ----- FORECASTS -----
const ForecastForm = lazy(() => import('../pages/Users/Reports/ForecastForm.js'));

// ----- ENGAGEHUB -----
const EngageHubForm = lazy(() => import('../pages/Users/EngageHub/EngageHubForm.jsx'));

// ----- ORTHINAS CONNECT (Communication Page) -----
const OrthinasConnectPage = lazy(() => import('../pages/Users/OrthinasConnect/OrthinasConnectPage.jsx'));

// ----- LEARNING PORTAL / LESSONS -----
const Learning = lazy(() => import('../pages/Users/Learning/Learning.js'));
const LessonDetail = lazy(() => import('../pages/Users/Learning/LessonDetail.js'));

// ----- CRM FEATURES (Private Access) -----
const CrmFeaturesList = lazy(() => import('../pages/Users/AdminOnly/CrmFeaturesList.jsx'));
const CrmFeatureForm = lazy(() => import('../pages/Users/AdminOnly/CrmFeatureForm.jsx'));

// ----- SOLUTIONS (using the "solutions" collection) -----
const SolutionsList = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Users/AdminOnly/SolutionsList.jsx'));
const SolutionForm = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Users/AdminOnly/SolutionForm.jsx'));

// ----- CHAT WIDGET CREATOR -----
// New page for creating a chat widget and generating an embed code.
const CreateChatWidgetPage = lazy(() =>
  import('../pages/Users/ChatWidgetCreator/CreateChatWidgetPage.jsx')
);

const CustomerJourneyMappingPage = lazy(() =>
  import('../pages/Users/JourneyMapping/CustomerJourneyMappingPage.jsx')
);
const ListJourneyMappingPage = lazy(() =>
  import('../pages/Users/JourneyMapping/ListJourneyMappingPage.jsx')
);

// ----- USE CASES -----
// New Use Cases pages for inspiring leads.
const UseCaseListPage = lazy(() => import('../pages/Users/AdminOnly/UseCaseListPage.jsx'));
const UseCaseFormPage = lazy(() => import('../pages/Users/AdminOnly/UseCaseFormPage.jsx'));

// ----- CUSTOMER BUNDLES -----
// New page for customers to create their own bundles.
const CustomerBundleForm = lazy(() => import('../pages/Users/CustomerBundleForm.jsx'));

export const protectedRoutes = [
  // ADMIN
  {
    path: '/admin/notifications/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <AdminCreateNotification />
      </ProtectedRoute>
    ),
  },
  {
    path: '/blog/create',
    element: (
      <ProtectedRoute>
        <CreateBlogPost />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/rcf-dashboard',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <RcfUnityDashboard />
      </ProtectedRoute>
    ),
  },

  // CUSTOMER JOURNEY MAPPING
  {
    path: '/journey-mapping/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CustomerJourneyMappingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/journey-mapping',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ListJourneyMappingPage />
      </ProtectedRoute>
    ),
  },

  // SETTINGS / ACCOUNT
  {
    path: '/settings',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <YourAccount />
      </ProtectedRoute>
    ),
  },

  // DASHBOARD, DOCS
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/articles',
    element: (
      <ProtectedRoute>
        <ArticlesDocumentation />
      </ProtectedRoute>
    ),
  },

  // OBJECT TYPES
  {
    path: '/objectTypes/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectTypeForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/objectTypes/edit/:objectTypeId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectTypeForm />
      </ProtectedRoute>
    ),
  },

  // OBJECT ITEMS
  {
    path: '/create/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectItemForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/edit/:objectType/:objectId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectItemForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/list/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ListObjectsPage />
      </ProtectedRoute>
    ),
  },

  // PUBLIC FORM EDITOR
  {
    path: '/formEditor/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <FormEditorPage />
      </ProtectedRoute>
    ),
  },

  // REPORTS
  {
    path: '/reports',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ListReportsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ReportForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/edit/:reportId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ReportForm />
      </ProtectedRoute>
    ),
  },

  // FORECASTS
  {
    path: '/forecast/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ForecastForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/forecast/edit/:forecastId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ForecastForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/forecast/view/:forecastId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ForecastForm />
      </ProtectedRoute>
    ),
  },

  // ENGAGEHUB
  {
    path: '/engagehub/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <EngageHubForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/engagehub/edit/:engageHubId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <EngageHubForm />
      </ProtectedRoute>
    ),
  },

  // ORTHINAS CONNECT
  {
    path: '/orthinas-connect',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <OrthinasConnectPage />
      </ProtectedRoute>
    ),
  },

  // LEARNING PORTAL
  {
    path: '/learning',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Learning />
      </ProtectedRoute>
    ),
  },
  {
    path: '/learning/course/:courseId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Learning />
      </ProtectedRoute>
    ),
  },
  {
    path: '/learning/lesson/:lessonId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <LessonDetail />
      </ProtectedRoute>
    ),
  },

  // CRM FEATURES
  {
    path: '/crmFeatures',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeaturesList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/crmFeatures/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeatureForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/crmFeatures/edit/:featureId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeatureForm />
      </ProtectedRoute>
    ),
  },

  // SOLUTIONS
  {
    path: '/solutions/list',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <SolutionsList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/solutions/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <SolutionForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/solutions/edit/:solutionId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <SolutionForm />
      </ProtectedRoute>
    ),
  },

  // ----- CHAT WIDGET CREATOR -----
  {
    path: '/chat-widget-creator',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CreateChatWidgetPage />
      </ProtectedRoute>
    ),
  },

  // ----- USE CASES -----
  {
    path: '/useCases',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <UseCaseListPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/useCases/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <UseCaseFormPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/useCases/edit/:id',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <UseCaseFormPage />
      </ProtectedRoute>
    ),
  },

  // ----- CUSTOMER BUNDLES -----
  {
    path: '/bundles/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CustomerBundleForm />
      </ProtectedRoute>
    ),
  },
];
