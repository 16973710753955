import React, { useContext, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Menu, MenuItem, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const QuickLinksBar = ({ dashboardModule, objectTypes = [] }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  // Filter object types based on the dashboard module and permissions.
  const filteredTypes = useMemo(() => {
    if (!dashboardModule) return [];
    const dashboardModuleLower = dashboardModule.toLowerCase();
    return objectTypes.filter((ot) => {
      const otModule = (ot.module || '').toLowerCase();
      return (
        otModule === dashboardModuleLower &&
        (ot.system === true || ot.userId === currentUser.uid) &&
        !ot.adminViewOnly
      );
    });
  }, [objectTypes, dashboardModule, currentUser]);

  // Derive singular and plural labels for each object type.
  const typesWithLabels = useMemo(() => {
    return filteredTypes.map((ot) => {
      const singularLabel =
        ot.singularName ||
        ot.title ||
        (ot.id.charAt(0).toUpperCase() + ot.id.slice(1));
      const pluralLabel =
        ot.pluralName ||
        ot.title ||
        (ot.id.charAt(0).toUpperCase() + ot.id.slice(1));
      return {
        id: ot.id,
        singularLabel,
        pluralLabel,
      };
    });
  }, [filteredTypes]);

  // State for tracking the currently open dropdown menu.
  const [openMenu, setOpenMenu] = useState({ typeId: null, anchorEl: null });

  // Memoized event handlers.
  const handleMenuOpen = useCallback((typeId, event) => {
    setOpenMenu({ typeId, anchorEl: event.currentTarget });
  }, []);

  const handleMenuClose = useCallback(() => {
    setOpenMenu({ typeId: null, anchorEl: null });
  }, []);

  const handleNavigate = useCallback((path) => {
    navigate(path);
    handleMenuClose();
  }, [navigate, handleMenuClose]);

  if (typesWithLabels.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {typesWithLabels.map((type) => (
        <Box key={type.id}>
          <Button
            variant="text"
            color="inherit"
            endIcon={<ArrowDropDownIcon />}
            onClick={(e) => handleMenuOpen(type.id, e)}
            sx={{ textTransform: 'none' }}
            aria-controls={openMenu.typeId === type.id ? `menu-${type.id}` : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu.typeId === type.id ? 'true' : 'false'}
          >
            {type.singularLabel}
          </Button>
          <Menu
            id={`menu-${type.id}`}
            anchorEl={openMenu.anchorEl}
            open={openMenu.typeId === type.id}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleNavigate(`/create/${type.id}`)}>
              Add {type.singularLabel}
            </MenuItem>
            <MenuItem onClick={() => handleNavigate(`/list/${type.id}`)}>
              View {type.pluralLabel}
            </MenuItem>
          </Menu>
        </Box>
      ))}
    </Stack>
  );
};

QuickLinksBar.propTypes = {
  dashboardModule: PropTypes.string.isRequired,
  objectTypes: PropTypes.arrayOf(PropTypes.object)
};

export default QuickLinksBar;
