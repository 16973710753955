// File: src/components/Navbar/DesktopLoggedOutNavItems.jsx
import React, { useState } from 'react';
import { Stack, Tooltip, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function DesktopLoggedOutNavItems({ buttonStyles }) {
  // Use a single state object for all anchor elements keyed by menu label
  const [menuAnchorEls, setMenuAnchorEls] = useState({});

  const handleMenuOpen = (label, event) => {
    setMenuAnchorEls((prev) => ({ ...prev, [label]: event.currentTarget }));
  };

  const handleMenuClose = (label) => {
    setMenuAnchorEls((prev) => ({ ...prev, [label]: null }));
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
    
    </Stack>
  );
}
