// File: src/routes/publicRoutes.js

import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// Only keep the pages you still support:
const SignIn = lazy(() => import('../pages/Auth/SignIn'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const SearchPage = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Resources/SearchPage.jsx'));

// All other routes will redirect to the default page (Sign In)
export const publicRoutes = [
  { path: '/', element: <SignIn /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/search', element: <SearchPage /> },
  
  // Catch-all: redirect any other URL to the default page
  { path: '*', element: <Navigate to="/" replace /> },
];
